/* eslint-disable @next/next/no-img-element */
import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";

import { useLogEvent } from "../../contexts/Analytics";
import { ROUTE_PREFIX } from "../../constants";
import { Link } from "../../containers";
import { getUtmParamsFromQueryParams } from "../../utils/analytics";

export default function BlogCardTile({
  title,
  name,
  description,
  slug,
  imageUrl,
  analyticsProps,
}) {
  const url = `/${ROUTE_PREFIX.COMPANY}/post/${slug}`;
  const router = useRouter();
  const { logEvent } = useLogEvent({
    event: "Link Clicked",
    properties: {
      title,
      sourceElement: analyticsProps?.sourceElement,
      sourceName: analyticsProps?.sourceName,
      sourcePage: analyticsProps?.sourcePage,
      sourcePageType: analyticsProps?.sourcePageType,
      ...getUtmParamsFromQueryParams(router.query),
    },
  });

  const handleClick = async (event) => {
    event.preventDefault();
    await logEvent();
    router.push(url);
  };

  return (
    <Link href={url} onClick={handleClick}>
      <div className="overflow-hidden rounded aspect-w-16 aspect-h-9">
        <img
          className="object-cover transform transition-transform group-hover:scale-110 filter drop-shadow rounded"
          src={imageUrl}
          alt={name}
        />
      </div>
      <h3 className="gn-text-sm mt-sm text-white line-clamp-3">{name}</h3>
      <p className="gn-text-xs mt-xs text-gray-500 line-clamp-4">
        {description}
      </p>
    </Link>
  );
}

BlogCardTile.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  slug: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  analyticsProps: PropTypes.shape({
    sourceId: PropTypes.string,
    sourceName: PropTypes.string,
    sourcePage: PropTypes.string,
    sourcePageType: PropTypes.string,
    sourceElement: PropTypes.string,
  }),
};
