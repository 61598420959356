import { useQuery } from "@tanstack/react-query";

import api from "../../../api";
import { createLayoutFetchers } from "../helpers";

export default function useDataLoader({ resolver, params = {} } = {}) {
  const clientFetcher = api();
  const layoutFetchers = createLayoutFetchers(clientFetcher);

  const { data, isInitialLoading } = useQuery(
    [resolver, ...Object.values(params)],
    () => layoutFetchers[resolver](params),
    {
      enabled: Boolean(resolver),
    },
  );

  return { data, isLoading: isInitialLoading };
}
