export default {
  SHELVES: "shelves",
  SHELVES_DEFAULT: "shelves_default",
  SPOTLIGHT: "spotlight",
  HERO_VIDEO: "hero_video",
  HERO_VIDEO_DEFAULT: "hero_video_default",
  HERO_UNIT: "hero_unit",
  HERO_UNIT_CAROUSEL: "heroUnitCarousel",
  CUSTOM_SHELF: "custom_shelf",
  RECENTLY_WATCHED_SHELF: "recently_watched_shelf",
  FAVORITES_SHELF: "favorites_shelf",
};
