import { ROUTE_PREFIX } from "../constants";
import { AGE_GATED_URLS } from "../constants/urls";

function isSuperNoodleMarketingUrl(url) {
  if (!url) return false;
  return AGE_GATED_URLS.some((ageGatedPath) => url.includes(ageGatedPath));
}

function transformSuperNoodleMarketingUrlToCurriculum(url, curriculum) {
  const hasAccessToSuperNoodle = !!curriculum;

  if (hasAccessToSuperNoodle && isSuperNoodleMarketingUrl(url)) {
    return `/${ROUTE_PREFIX.CURRICULUM}/${curriculum.id}`;
  }

  return url;
}

function requiresAgeGate(path, curriculum) {
  return !curriculum && isSuperNoodleMarketingUrl(path);
}

export { transformSuperNoodleMarketingUrlToCurriculum, requiresAgeGate };
