import React from "react";
import {
  Spotlight as PrimitiveSpotlight,
  Skeleton,
} from "@gonoodle/gn-universe-ui";
import { concatenateQueryParams } from "@gonoodle/gn-universe-utils";
import { useRouter } from "next/router";
import {
  PAGES,
  SECTIONS_TYPES,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";

import { useDataLoader } from "../../hooks";
import {
  pushExternal,
  generateSpotlightPathByType,
} from "../../../../utils/route";
import { SPOTLIGHT_TYPES, ROUTE_PREFIX } from "../../../../constants";
import { useLogEvent } from "../../../../contexts/Analytics";
import { getUtmParamsFromQueryParams } from "../../../../utils/analytics";

const JSURL = require("jsurl");

function normalizeSpotlights(spotlights = []) {
  const [spotlight] = spotlights;
  const {
    destinationType = SPOTLIGHT_TYPES.TAG,
    description,
    content,
    loopingVideo,
    image,
  } = spotlight;

  const isExternal = destinationType === SPOTLIGHT_TYPES.EXTERNAL;
  const url = isExternal
    ? spotlight.url
    : generateSpotlightPathByType(destinationType, {
        id: content.id,
        slug: content.slug,
      });

  return { url, description, image, loopingVideo, isExternal, destinationType };
}

export default function Spotlight({ templateOptions, fetchers }) {
  const router = useRouter();
  const { title, description, label, theme } = templateOptions;
  const [fetcher] = fetchers;
  const { data: spotlights, isLoading } = useDataLoader(fetcher);
  const spotlight = spotlights ? normalizeSpotlights(spotlights) : {};

  const { logEvent: logSpotlightPressed } = useLogEvent({
    event: "Link Clicked",
    properties: {
      title,
      url: spotlight.url,
      ...getUtmParamsFromQueryParams(router.query),
    },
    options: {
      referrer: {
        sourcePage: PAGES.HOME,
        sourcePageType: PAGES.HOME,
        sourceElement: SECTIONS_TYPES.SPOTLIGHT,
        sourceName: title,
      },
    },
  });

  const onSpotlightPress = () => {
    const { destinationType } = spotlight;

    if (spotlight.isExternal) {
      pushExternal(spotlight.url, {
        sourcePage: PAGES.HOME,
        sourcePageType: PAGES.HOME,
        sourceElement: SECTIONS_TYPES.SPOTLIGHT,
        sourceName: title,
      });
    } else {
      const pathWithParams = concatenateQueryParams(
        `/${
          destinationType === SPOTLIGHT_TYPES.VIDEO
            ? ROUTE_PREFIX.VIDEOS
            : destinationType === SPOTLIGHT_TYPES.TAG
            ? ROUTE_PREFIX.TAGS
            : ROUTE_PREFIX.ACTIVITIES
        }/[...identity]`,
        {
          referrer: JSURL.stringify({
            sourcePage: PAGES.HOME,
            sourcePageType: PAGES.HOME,
            sourceElement: SECTIONS_TYPES.SPOTLIGHT,
            sourceName: title,
          }),
        },
      );
      router.push(pathWithParams, spotlight.url);
    }
    logSpotlightPressed();
  };

  if (isLoading) {
    return <Skeleton className="h-[500px] md:h-[720px] lg:h-[420px]" />;
  }

  return (
    <PrimitiveSpotlight
      source={spotlight.loopingVideo}
      fallbackImageSource={spotlight.image}
      title={title}
      description={description}
      onClick={onSpotlightPress}
      buttonLabel={label}
      bgColor={theme.background}
      textTheme={theme.color}
    />
  );
}
