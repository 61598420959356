import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { invariant } from "@gonoodle/gn-universe-utils";

import { createLayoutFetchers } from "./helpers";
import { FETCHERS } from "./constants";
import {
  CardList,
  Carousel,
  Spotlight,
  HeroVideo,
  HeroUnit,
} from "./components";

function useInvalidateQueriesOnMutation(mutationKeyToQueryKeyMap) {
  const queryClient = useQueryClient();
  const successfullyHandledMutationIds = React.useRef(new Set());
  const targetMutationKeys = Object.keys(mutationKeyToQueryKeyMap);

  React.useEffect(() => {
    const mutationCache = queryClient.getMutationCache();

    const onMutationUpdate = (mutationEvent) => {
      if (mutationEvent.mutation?.state?.status === "success") {
        const { mutationId } = mutationEvent.mutation;

        if (!successfullyHandledMutationIds.current.has(mutationId)) {
          let mutationEventKeys = mutationEvent.mutation.options.mutationKey;

          // TODO: mutationKey can be of any type, this is a temporary fix to handle string and array.
          // We should reconsider how we handle data syncing between LayoutRenderer and the reset of the application.
          if (!mutationEventKeys) {
            mutationEventKeys = [];
          } else if (typeof mutationEventKeys === "string") {
            mutationEventKeys = [mutationEventKeys];
          }

          const relevantMutationKey = mutationEventKeys.find((key) =>
            targetMutationKeys.includes(key),
          );

          if (relevantMutationKey) {
            const queriesToBeInvalidated =
              mutationKeyToQueryKeyMap[relevantMutationKey];

            queriesToBeInvalidated.forEach((queryKey) => {
              queryClient.invalidateQueries({ queryKey: [queryKey] });
            });

            // Mark this mutation as successfully handled to prevent repeat invalidations
            successfullyHandledMutationIds.current.add(mutationId);
          }
        }
      }
    };

    const unsubscribeFromMutationCache = mutationCache.subscribe(
      onMutationUpdate,
    );

    return () => unsubscribeFromMutationCache();
  }, [queryClient, mutationKeyToQueryKeyMap, targetMutationKeys]);
}

export default function LayoutRenderer({
  layout = [],
  mutationToQueryInvalidationMap = [],
}) {
  const components = {
    List: CardList,
    spotlight: Spotlight,
    HeroVideo,
    HeroUnit,
    HeroUnitCarousel: Carousel,
    CustomShelf: CardList,
  };

  useInvalidateQueriesOnMutation(mutationToQueryInvalidationMap);

  return layout.map(({ key, type, templateOptions, fetcher }) => {
    // Check if the component type is registered
    if (type in components) {
      // Render the component with its templateOptions
      const Component = components[type];
      return (
        <Component
          key={key}
          templateOptions={templateOptions}
          fetchers={fetcher}
        />
      );
    }
    // If the component type is not registered, render an error message
    invariant(
      false,
      `LayoutRenderer: Component type ${type} is not registered`,
    );

    return null;
  });
}

export function prefetchLayout(layout, fetcher, queryClient) {
  const layoutFetchers = createLayoutFetchers(fetcher);

  const prefetchOperations = layout.map(
    ({ fetcher: [{ resolver, params = {} }] }) =>
      queryClient.prefetchQuery([resolver, ...Object.values(params)], () =>
        layoutFetchers[resolver](params),
      ),
  );

  return Promise.all(prefetchOperations);
}

export { FETCHERS };
