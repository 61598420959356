import { FETCHERS } from "../constants";

export default function createLayoutFetchers(fetcher) {
  return {
    [FETCHERS.SHELVES]: ({ id }) => fetcher.getShelf(id),
    [FETCHERS.SHELVES_DEFAULT]: ({ position }) =>
      fetcher.getDefaultShelf(position),
    [FETCHERS.SPOTLIGHT]: ({ spotlightId }) => fetcher.spotlight(spotlightId),
    [FETCHERS.HERO_VIDEO]: ({ videoId }) => fetcher.getHeroVideo(videoId),
    [FETCHERS.HERO_VIDEO_DEFAULT]: () => fetcher.getDefaultHeroVideo(),
    [FETCHERS.HERO_UNIT]: ({ heroUnitId }) => fetcher.getHeroUnit(heroUnitId),
    [FETCHERS.HERO_UNIT_CAROUSEL]: ({ id }) => fetcher.getHeroUnitCarousel(id),
    [FETCHERS.CUSTOM_SHELF]: ({ id }) => fetcher.getCustomShelf(id),
    [FETCHERS.RECENTLY_WATCHED_SHELF]: ({ id }) =>
      fetcher.getRecentlyWatchedShelf(id),
    [FETCHERS.FAVORITES_SHELF]: ({ id }) => fetcher.getFavoritesShelf(id),
  };
}
